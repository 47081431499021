import React from "react";
import { Destination, FormField } from "@prequel/react";

import { FormField as FormFieldComponent } from "@prequel-internal/react-components";

type InputWrapperProps = {
  field: FormField & { form_element: "input" };
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  disabled: boolean;
};

const InputWrapper = ({
  field,
  destination,
  setDestinationField,
  disabled,
}: InputWrapperProps) => {
  return (
    <FormFieldComponent
      label={field.label}
      id={field.name}
      type={field.input_type ?? "text"}
      subtext={field.description}
      placeholder={field.placeholder}
      value={destination?.[field.name]?.toString()}
      onChangeHandler={(value) => setDestinationField(field.name, value)}
      required={field.required}
      disabled={disabled}
    />
  );
};

export default InputWrapper;
