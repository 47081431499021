import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "@prequel-internal/react-components";
import { useTypedDispatch, useTypedSelector } from "../../store"; // Redux - global state type import
import {
  fetchMagicLink,
  selectMagicLink,
  selectIsLoading,
} from "../../store/magic_link/magic_link.duck";

import Connect from "../Connect";
import NotFound from "../NotFound";

type MagicLinkProps = {
  prodEnv: boolean;
};
const MagicLink = ({ prodEnv }: MagicLinkProps) => {
  const { orgId, linkId } = useParams();

  const magicLink = useTypedSelector(selectMagicLink);
  const isLoading = useTypedSelector(selectIsLoading);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    // If an orgId and linkId are loaded, we will use them to attemp to fetch the link details
    if (orgId && linkId) {
      dispatch(
        fetchMagicLink({ orgId: orgId, linkId: linkId, isProd: prodEnv })
      );
    }
  }, [orgId, linkId, dispatch]);

  if (isLoading) {
    return (
      <div className="w-screen h-screen bg-gray-50 flex flex-col justify-center items-center">
        <Spinner message="Loading connection details..." />
      </div>
    );
  } else {
    // If the "host" is populated, assume the link is "good" and show the Connect form
    // Otherwise, display an "expired link" notice
    if (magicLink?.has_been_used) {
      return (
        <NotFound
          header="Link expired."
          message="Sorry, the link you provided has already been used."
          orgId={orgId}
        />
      );
    } else if (magicLink && orgId && linkId) {
      return (
        <Connect
          magicLink={magicLink}
          orgId={orgId}
          linkId={linkId}
          prodEnv={prodEnv}
        />
      );
    } else {
      return (
        <NotFound
          header="Not found."
          message="Sorry, the link you provided either does not exist or has expired."
        />
      );
    }
  }
};

export default MagicLink;
