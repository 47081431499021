import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery } from "redux-saga/effects";
import { RootState, createWorkerSaga } from "..";
import { AppError } from "../../axios";

import { MagicLink, MagicLinkArgs } from "../../store/magic_link";
import MagicLinkService from "../../store/magic_link/magic_link.service";

type MagicLinkState = {
  magicLink: MagicLink | undefined;
  isLoading: boolean;
};
const initialState: MagicLinkState = {
  magicLink: undefined,
  isLoading: false,
};

const fetchMagicLinkReducer: CaseReducer<
  MagicLinkState,
  PayloadAction<MagicLinkArgs>
> = (state) => {
  state.isLoading = true;
};

const fetchMagicLinkSuccessReducer: CaseReducer<
  MagicLinkState,
  PayloadAction<MagicLink>
> = (state, action) => {
  state.magicLink = action.payload;
  state.isLoading = false;
};

const fetchMagicLinkFailureReducer: CaseReducer<
  MagicLinkState,
  PayloadAction<AppError>
> = (state) => {
  state.isLoading = false;
};

function* watchFetchMagicLink() {
  yield takeEvery(
    fetchMagicLink.type,
    createWorkerSaga(
      fetchMagicLink,
      fetchMagicLinkSuccess,
      fetchMagicLinkFailure,
      MagicLinkService.getMagicLink
    )
  );
}

const magicLinkSlice = createSlice({
  name: "magicLink",
  initialState,
  reducers: {
    fetchMagicLink: fetchMagicLinkReducer,
    fetchMagicLinkSuccess: fetchMagicLinkSuccessReducer,
    fetchMagicLinkFailure: fetchMagicLinkFailureReducer,
  },
});

export const { fetchMagicLink, fetchMagicLinkSuccess, fetchMagicLinkFailure } =
  magicLinkSlice.actions;

// Selectors
export const selectIsLoading = (state: RootState) => state.magicLink.isLoading;
export const selectMagicLink = (state: RootState) => state.magicLink.magicLink;

export function* magicLinkSagas() {
  yield all([watchFetchMagicLink()]);
}

export default magicLinkSlice.reducer;
