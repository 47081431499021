import axios, { AxiosError } from "axios";
import { EnhancedStore } from "@reduxjs/toolkit";
import { env } from "../env";

let reduxStore: EnhancedStore;
export const injectStore = (store: EnhancedStore) => {
  reduxStore = store;
};

export const API_VERSION_HEADER = "X-Prequel-Api-Version";
export const API_VERSION_2023_12_01 = "2023-12-01";

// creates a copy of the axios instance for changing defaults
const instance = axios.create({
  baseURL: env.REACT_APP_API_SERVER,
});

instance.defaults.headers.common["Content-Type"] = "application/json";

instance.interceptors.request.use((config) => {
  if (
    config.data?.destination &&
    typeof config.data === "object" &&
    !Array.isArray(config.data)
  ) {
    delete config.data.destination.type; // Delete the type field from destination
  }
  return config;
});

export enum ApiRoutes {
  LINK = "/link",
  EXPIRED = "/expired",
  SUCCESS = "/success",
}

// ApiResponse and ErrorResponse are what we receive in then/catch clauses
export type ApiResponse<T> = {
  data: {
    status: string;
    data: T;
    message: string;
  };
};
export type ErrorResponse = AxiosError<{ message: string }, any>;

// AppError is the error type we pass around the application
export type AppError = {
  error: {
    message: string;
    suppressGlobalNotification?: boolean;
    statusCode?: number;
  };
};

export type RequestStatus = "processing" | "success" | "error" | undefined;

export default instance;
