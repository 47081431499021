import React, { Fragment, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  BucketVendor,
  PreparedDestination,
  useDestination,
  useDestinationForm,
  Destination,
  prepareDestinationWithForm,
  getDefaultAuthMethod,
  getDefaultBucketVendor,
} from "@prequel/react";
import {
  Checkbox,
  Button,
  ButtonStyle,
  Spinner,
} from "@prequel-internal/react-components";

import BrandedLogo from "../../components/BrandedLogo";
import { ReactComponent as SpinnerIcon } from "../../assets/icons/spinner.svg";
import { ReactComponent as AlertCircleIcon } from "../../assets/icons/alert-circle.svg";
import { ReactComponent as AlertTriangleIcon } from "../../assets/icons/alert-triangle.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as EllipsisIcon } from "../../assets/icons/ellipsis.svg";

import { useState } from "react";
import { useTypedDispatch, useTypedSelector } from "../../store"; // Redux - global state type import
import {
  createDestination,
  testDestination,
  resetTest,
  selectDestinationTest,
  selectDestinationCreate,
} from "../../store/destination/destination.duck";
import { MagicLink } from "../../store/magic_link";
import { env } from "../../env";
import ConstWrapper from "./ConstWrapper";
import SelectWrapper from "./SelectWrapper";
import InputWrapper from "./InputWrapper";
import TextAreaWrapper from "./TextAreaWrapper";
import RadioWrapper from "./RadioWrapper";

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

const ALL_CURRENT_FUTURE_MODELS = ["*"];

type ConnectProps = {
  orgId: string;
  linkId: string;
  magicLink: MagicLink;
  prodEnv: boolean;
};
const Connect = ({ magicLink, orgId, linkId, prodEnv }: ConnectProps) => {
  const destinationTest = useTypedSelector(selectDestinationTest);
  const destinationCreate = useTypedSelector(selectDestinationCreate);
  const [destination, setDestination] = useDestination({
    name: magicLink.destination_name,
    vendor: magicLink.vendor,
    host: magicLink.host,
    bucket_name: magicLink.bucket_name,
    enabled_models: ALL_CURRENT_FUTURE_MODELS,
    metastore: magicLink.metastore,
    // in the new API version, destinations use a bucket_ prefix for the bucket_vendor field
    // and magic links need to be updated to match
    bucket_vendor: magicLink.bucket_vendor
      ? (("bucket_" + magicLink.bucket_vendor) as BucketVendor)
      : undefined,
  });
  const form = useDestinationForm(destination, orgId, {
    host: env.REACT_APP_API_SERVER,
    vendorQuery: magicLink.vendor,
  });

  const [modelsError, setModelsError] = useState<string>();
  const [allFutureModels, setAllFutureModels] = useState<boolean>(true);

  const preparedDestination: PreparedDestination = useMemo(
    () => prepareDestinationWithForm(destination, form),
    [destination, form]
  );

  const selectedVendor = useMemo(() => {
    if (form) {
      const { fields } = form[0];
      if (fields[0].form_element === "select") {
        return fields[0].enum?.find(({ key }) => key === magicLink.vendor);
      }
    }
  }, [form, destination.vendor]);

  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  useEffect(() => {
    const defaultAuthMethod = getDefaultAuthMethod(magicLink.vendor);
    const defaultBucketVendor = getDefaultBucketVendor(magicLink.vendor);
    setDestination((currentDestination) => ({
      ...currentDestination,
      auth_method: defaultAuthMethod,
      bucket_vendor: currentDestination.bucket_vendor || defaultBucketVendor,
    }));
  }, []);

  // Reset test on form change
  useEffect(() => {
    dispatch(resetTest());
  }, [dispatch, destination]);

  useEffect(() => {
    setModelsError(undefined);
  }, [destination.enabled_models]);

  useEffect(() => {
    if (allFutureModels) {
      setDestinationField("enabled_models", ALL_CURRENT_FUTURE_MODELS);
    } else {
      setDestinationField("enabled_models", magicLink.available_models);
    }
  }, [allFutureModels]);

  const updateEnabledModels = (isEnabled: boolean, modelName: string) => {
    let updatedModels: string[] | undefined = [];
    if (isEnabled) {
      updatedModels = [...(destination.enabled_models ?? []), modelName];
    } else {
      updatedModels = destination.enabled_models?.filter(
        (m) => m !== modelName
      );
    }
    setDestinationField("enabled_models", updatedModels);
  };

  // Handle destination submission
  const onConnectHandler = () => {
    dispatch(
      createDestination({
        magiclinkargs: { orgId: orgId, linkId: linkId, isProd: prodEnv },
        destination: preparedDestination,
        redirect: () =>
          navigate("success", {
            replace: false,
            state: { redirectUrl: magicLink.redirect_url },
          }),
      })
    );
  };

  // Handle destination test
  const onTestHandler = () => {
    if (
      !destination.enabled_models ||
      destination.enabled_models?.length === 0
    ) {
      setModelsError("This is a required field");
      return;
    }

    dispatch(
      testDestination({
        magiclinkargs: { orgId: orgId, linkId: linkId, isProd: prodEnv },
        destination: preparedDestination,
      })
    );
  };

  const setDestinationField = (
    key: keyof Destination,
    value: string | string[] | boolean | number | undefined
  ) =>
    setDestination((currentDestination) => ({
      ...currentDestination,
      [key]: value,
    }));

  // Intercept native form submission, prevent default, and run test
  // We use the default form submission event so that we can borrow the browsers built-in support for handling missing required fields
  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onTestHandler();
  };

  const isFixedField = (fieldName: string) =>
    [
      "name",
      "vendor",
      "host",
      "bucket_vendor",
      "bucket_name",
      "metastore",
    ].includes(fieldName);

  return (
    <>
      <nav
        className="bg-white-100 bg-white border-b border-gray-200"
        aria-label="Global"
      >
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center px-2 lg:px-0">
              <div className="flex-shrink-0 inline-flex items-center justify-center w-36 rounded-lg">
                <BrandedLogo orgId={orgId} />
              </div>
            </div>
          </div>
        </div>
      </nav>

      <main className="max-w-lg mx-auto pt-10 pb-12 px-4 lg:pb-16">
        {form ? (
          <>
            <form onSubmit={onSubmitHandler}>
              <div className="space-y-6">
                {form.map((section) => {
                  return (
                    <Fragment key={section.id}>
                      <div>
                        {section.id === 1 ? (
                          <div>
                            <h1 className="text-lg leading-6 font-medium text-gray-900">
                              Connect destination
                            </h1>
                          </div>
                        ) : (
                          <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {section.title}
                          </label>
                        )}
                        {section.subtitle ? (
                          <div className="mt-1 mb-4">
                            <p className="mt-1 text-sm text-gray-500">
                              {section.subtitle}
                            </p>
                          </div>
                        ) : (
                          <br />
                        )}
                        <div className="space-y-4">
                          {section.fields.map((field) => {
                            if (field.const) {
                              return (
                                <div key={field.name}>
                                  <ConstWrapper
                                    field={field}
                                    setDestinationField={setDestinationField}
                                  />
                                </div>
                              );
                            } else if (field.form_element === "select") {
                              return (
                                <div key={field.name}>
                                  <SelectWrapper
                                    field={field}
                                    destination={destination}
                                    setDestinationField={setDestinationField}
                                    disabled={
                                      destinationTest?.status ===
                                        "processing" || isFixedField(field.name)
                                    }
                                  />
                                </div>
                              );
                            } else if (field.form_element === "input") {
                              return (
                                <div key={field.name}>
                                  <InputWrapper
                                    field={field}
                                    destination={destination}
                                    setDestinationField={setDestinationField}
                                    disabled={
                                      destinationTest?.status ===
                                        "processing" || isFixedField(field.name)
                                    }
                                  />
                                </div>
                              );
                            } else if (field.form_element === "textarea") {
                              return (
                                <div key={field.name}>
                                  <TextAreaWrapper
                                    field={field}
                                    destination={destination}
                                    setDestinationField={setDestinationField}
                                    disabled={
                                      destinationTest?.status ===
                                        "processing" || isFixedField(field.name)
                                    }
                                  />
                                </div>
                              );
                            } else if (field.form_element === "radio") {
                              return (
                                <div key={field.name}>
                                  <RadioWrapper
                                    field={field}
                                    destination={destination}
                                    setDestinationField={setDestinationField}
                                    disabled={
                                      destinationTest?.status ===
                                        "processing" || isFixedField(field.name)
                                    }
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                      <div className="h-px w-full bg-gray-200" />{" "}
                      {/* Divider  */}
                      {section.id === 1 && selectedVendor && (
                        <div>
                          <label
                            htmlFor="description"
                            className="block text-sm font-medium text-gray-700"
                          >
                            {`Configure your ${selectedVendor.display} connection`}
                          </label>
                          <div className="mt-1">
                            <p className="mt-1 text-sm text-gray-500">
                              If you’re unsure how to get started or want to
                              read about our recommended configuration,{" "}
                              <a
                                href={
                                  magicLink.destination_docs_override ||
                                  selectedVendor.docs
                                }
                                target="_blank"
                                rel="noreferrer"
                                className="font-medium text-primary-600 hover:text-primary-500"
                              >
                                {`view our documentation on ${selectedVendor.display}.`}
                              </a>
                            </p>
                          </div>
                        </div>
                      )}
                    </Fragment>
                  );
                })}
                {/* Special case for default value of "*", don't show model selectors */}
                {(magicLink.available_models.length > 1 ||
                  (magicLink.available_models.length == 1 &&
                    magicLink.available_models[0] !== "*")) && (
                  <div className="space-y-4">
                    <label className="block text-sm font-medium text-gray-700">
                      Select what models to receive
                    </label>
                    <div className="space-y-2">
                      {magicLink.available_models.map((m) => (
                        <Checkbox
                          key={m}
                          id={m}
                          label={m}
                          checked={
                            destination.enabled_models?.includes(m) ||
                            allFutureModels
                          }
                          setChecked={(isChecked: boolean) =>
                            updateEnabledModels(isChecked, m)
                          }
                          disabled={allFutureModels}
                        />
                      ))}
                      <Checkbox
                        key={"all_models_present_future"}
                        id={"all_models_present_future"}
                        label={"All current and future models"}
                        description={
                          "Any new tables added in the future will be synced automatically"
                        }
                        checked={allFutureModels}
                        setChecked={(isChecked: boolean) =>
                          setAllFutureModels(isChecked)
                        }
                      />
                    </div>
                    {modelsError && (
                      <p className="mt-1 text-xs font-medium text-red-600">
                        {modelsError}
                      </p>
                    )}
                  </div>
                )}
                <div className="relative pt-4">
                  <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500">
                    <div className="p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div
                            className={classNames(
                              "mx-auto flex items-center justify-center h-10 w-10 rounded-full",
                              destinationTest.status === "success"
                                ? "bg-emerald-100"
                                : "",
                              destinationTest.status === "error"
                                ? "bg-red-100"
                                : "",
                              destinationTest.status === "processing"
                                ? "bg-gray-100"
                                : "",
                              !destinationTest.status ? "bg-gray-100" : ""
                            )}
                          >
                            {destinationTest.status === "success" && (
                              <CheckIcon
                                className="h-5 w-5 text-emerald-600"
                                aria-hidden="true"
                              />
                            )}
                            {destinationTest.status === "error" && (
                              <AlertCircleIcon
                                className="h-5 w-5 text-red-600"
                                aria-hidden="true"
                              />
                            )}
                            {destinationTest.status === "processing" && (
                              <EllipsisIcon
                                className="h-5 w-5 text-gray-600"
                                aria-hidden="true"
                              />
                            )}
                            {!destinationTest.status && (
                              <AlertTriangleIcon
                                className="h-5 w-5 text-gray-600"
                                aria-hidden="true"
                              />
                            )}
                          </div>
                        </div>
                        <div className="ml-3 mt-1">
                          <h3 className="text-lg font-medium text-gray-800">
                            {destinationTest.status === "success" &&
                              "Connection successful!"}
                            {destinationTest.status === "error" &&
                              "Error connecting to destination."}
                            {destinationTest.status === "processing" &&
                              "Testing connection..."}
                            {!destinationTest.status &&
                              "Test connection before saving."}
                          </h3>
                          <div className="mt-2 mb-2 text-sm text-gray-700">
                            <p>
                              {destinationTest.status === "success" &&
                                "Connection was successfully established to this destination. The destination details can now be saved."}
                              {destinationTest.status === "error" && (
                                <>
                                  <span className="block mb-4">
                                    Try updating the destination details and try
                                    again.
                                  </span>
                                  <code className="text-xs">
                                    {destinationTest.message}
                                  </code>
                                </>
                              )}
                              {destinationTest.status === "processing" && ""}
                              {!destinationTest.status && ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Spacer element to match the height of the toolbar */}
                    <div aria-hidden="true">
                      <div className="h-px" />
                      <div className="py-2">
                        <div className="py-px">
                          <div className="h-9" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="absolute bottom-0 inset-x-px">
                    <div className="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <button
                            type="submit"
                            disabled={destinationTest.status === "processing"}
                            name="test"
                            id="test"
                            className={classNames(
                              "inline-flex py-2 px-4 rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 disabled:cursor-not-allowed",
                              destinationTest.status === "success"
                                ? " text-gray-700 border border-gray-300 bg-white hover:bg-gray-50"
                                : "",
                              destinationTest.status === "error"
                                ? "text-white bg-emerald-500 hover:bg-emerald-600"
                                : "",
                              destinationTest.status === "processing"
                                ? " text-gray-700 border border-gray-300 bg-white hover:bg-gray-50"
                                : "",
                              !destinationTest.status
                                ? "text-white bg-emerald-500 hover:bg-emerald-600"
                                : ""
                            )}
                          >
                            {destinationTest.status === "processing" ? (
                              <>
                                <SpinnerIcon
                                  className="animate-spin h-5 w-5 mr-3 text-gray-500"
                                  viewBox="0 0 24 24"
                                />{" "}
                                Testing Connection...
                              </>
                            ) : (
                              "Test Connection"
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="flex justify-end pt-6">
              <Button
                onClick={onConnectHandler}
                type={
                  destinationTest.status !== "success"
                    ? ButtonStyle.SECONDARY // If the test is not successful, this is not the primary button to highlight.
                    : ButtonStyle.PRIMARY // If the test is successful, this is the primary action -- submitting the form
                }
                disabled={
                  destinationTest.status !== "success" || // If the test is not successful, submission is prevented
                  destinationCreate.status === "processing" // If the form has been submitted and is waiting for a response, disable a second click
                }
                text={
                  <div className="flex">
                    {destinationCreate.status === "processing" && (
                      <Spinner.Inline className="mr-3 text-white" />
                    )}
                    Save Destination
                  </div>
                }
              />
            </div>
          </>
        ) : (
          <Spinner />
        )}
      </main>
    </>
  );
};

export default Connect;
