import React, { useMemo } from "react";
import {
  Destination,
  FormField,
  parseGcpIamRoleMetadata,
  parseServiceAccountKey,
} from "@prequel/react";
import { TextArea } from "@prequel-internal/react-components";

type TextAreaWrapperProps = {
  field: FormField & { form_element: "textarea" };
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  disabled: boolean;
};
const TextAreaWrapper = ({
  field,
  destination,
  setDestinationField,
  disabled,
}: TextAreaWrapperProps) => {
  const serviceAccountKeyIsValid = useMemo(
    () => !!parseServiceAccountKey(destination.service_account_key),
    [destination.service_account_key]
  );

  const gcpIamRoleMetadataIsValid = useMemo(
    () =>
      !!parseGcpIamRoleMetadata(
        destination.workload_identity_federation_metadata
      ),
    [destination.workload_identity_federation_metadata]
  );

  return (
    <TextArea
      id={field.name}
      label={field.label}
      placeholder={field.placeholder}
      subtext={field.description}
      value={destination[field.name]?.toString()}
      onChangeHandler={(value: string) => {
        setDestinationField(field.name, value);
      }}
      invalid={
        field.name === "service_account_key"
          ? !serviceAccountKeyIsValid
          : field.name === "workload_identity_federation_metadata"
          ? !gcpIamRoleMetadataIsValid
          : false
      }
      required={field.required}
      disabled={disabled}
    />
  );
};

export default TextAreaWrapper;
