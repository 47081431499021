import axios, {
  API_VERSION_2023_12_01,
  API_VERSION_HEADER,
  ApiResponse,
  AppError,
  ErrorResponse,
} from "../../axios";
import { PreparedDestination } from "@prequel/react";
import { MagicLinkArgs } from "../../store/magic_link";
import { WithRedirect } from "..";
import { TestDestinationResponse, TestDestinationResult } from ".";

const postDestination: (
  payload: WithRedirect<{
    magiclinkargs: MagicLinkArgs;
    destination: PreparedDestination;
  }>
) => Promise<WithRedirect<{}>> = ({ magiclinkargs, destination, redirect }) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .post(
      `/public/orgs/${magiclinkargs.orgId}/${environment}/magic-links/${magiclinkargs.linkId}/convert`,
      {
        destination: destination,
      },
      { headers: { [API_VERSION_HEADER]: API_VERSION_2023_12_01 } }
    )
    .then(() => ({
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Destination creation failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postTestDestination: ({
  magiclinkargs,
  destination,
}: {
  magiclinkargs: MagicLinkArgs;
  destination: PreparedDestination;
}) => Promise<TestDestinationResult> = ({ magiclinkargs, destination }) => {
  const environment = magiclinkargs.isProd ? "prod" : "staging";
  return axios
    .post(
      `/public/orgs/${magiclinkargs.orgId}/${environment}/magic-links/${magiclinkargs.linkId}/test-connection`,
      {
        destination: destination,
      },
      { headers: { [API_VERSION_HEADER]: API_VERSION_2023_12_01 } }
    )
    .then((response: ApiResponse<TestDestinationResponse>) => ({
      status: response.data.data?.status,
      message: response.data.message,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message ||
            "Destination connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getDestinationServiceAccount: (
  magicLinkArgs: MagicLinkArgs
) => Promise<string> = (magicLinkArgs) => {
  const environment = magicLinkArgs.isProd ? "prod" : "staging";
  return axios
    .post(
      `/public/orgs/${magicLinkArgs.orgId}/${environment}/magic-links/${magicLinkArgs.linkId}/generate-service-account`
    )
    .then(
      (response: ApiResponse<{ service_account_name: string }>) =>
        response.data.data.service_account_name
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message ||
            "Unable to get destination service account.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const DestinationService = {
  postDestination,
  postTestDestination,
  getDestinationServiceAccount,
};
export default DestinationService;
