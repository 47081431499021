import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery } from "redux-saga/effects";
import { PreparedDestination } from "@prequel/react";

import {
  RootState,
  WithRedirect,
  createRedirectSaga,
  createWorkerSaga,
} from "..";
import { AppError } from "../../axios";
import DestinationService from "./destination.service";
import { MagicLinkArgs } from "../magic_link";
import { DestinationRequest, TestDestinationResult } from ".";

type DestinationState = {
  destinationServiceAccount: string | undefined;
  destinationTest: DestinationRequest;
  destinationCreate: { status: undefined } | { status: "processing" };
};
const initialState: DestinationState = {
  destinationTest: { status: undefined },
  destinationCreate: { status: undefined },
  destinationServiceAccount: undefined,
};

const createDestinationReducer: CaseReducer<
  DestinationState,
  PayloadAction<
    WithRedirect<{
      magiclinkargs: MagicLinkArgs;
      destination: PreparedDestination;
    }>
  >
> = (state) => {
  state.destinationCreate = { status: "processing" };
};

const createDestinationSuccessReducer: CaseReducer<
  DestinationState,
  PayloadAction<{}>
> = (state) => {
  state.destinationCreate = { status: undefined };
};

const createDestinationFailureReducer: CaseReducer<
  DestinationState,
  PayloadAction<AppError>
> = (state) => {
  state.destinationCreate = { status: undefined };
};

const testDestinationReducer: CaseReducer<
  DestinationState,
  PayloadAction<{
    magiclinkargs: MagicLinkArgs;
    destination: PreparedDestination;
  }>
> = (state) => {
  state.destinationTest.status = "processing";
};

const testDestinationSuccessReducer: CaseReducer<
  DestinationState,
  PayloadAction<TestDestinationResult>
> = (state, action) => {
  state.destinationTest = {
    status: action.payload.status,
    message: action.payload.message,
  };
};

const testDestinationFailureReducer: CaseReducer<
  DestinationState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const sendTestDestinationLocalErrorReducer: CaseReducer<
  DestinationState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const resetTestReducer: CaseReducer<DestinationState, PayloadAction<void>> = (
  state
) => {
  state.destinationTest = { status: undefined };
};

const fetchDestinationServiceAccountReducer: CaseReducer<
  DestinationState,
  PayloadAction<MagicLinkArgs>
> = (state) => state;

const fetchDestinationServiceAccountSuccessReducer: CaseReducer<
  DestinationState,
  PayloadAction<string>
> = (state, action) => {
  state.destinationServiceAccount = action.payload;
};

const fetchDestinationServiceAccountFailureReducer: CaseReducer<
  DestinationState,
  PayloadAction<AppError>
> = (state) => state;

function* watchCreateDestination() {
  yield takeEvery(
    createDestination.type,
    createWorkerSaga(
      createDestination,
      createDestinationSuccess,
      createDestinationFailure,
      DestinationService.postDestination
    )
  );
}

function* watchTestDestination() {
  yield takeEvery(
    testDestination.type,
    createWorkerSaga(
      testDestination,
      testDestinationSuccess,
      testDestinationFailure,
      DestinationService.postTestDestination
    )
  );
}

function* watchFetchDestinationServiceAccount() {
  yield takeEvery(
    fetchDestinationServiceAccount.type,
    createWorkerSaga(
      fetchDestinationServiceAccount,
      fetchDestinationServiceAccountSuccess,
      fetchDestinationServiceAccountFailure,
      DestinationService.getDestinationServiceAccount
    )
  );
}

function* watchCreateDestinationSuccess() {
  yield takeEvery(createDestinationSuccess.type, createRedirectSaga());
}

const destinationSlice = createSlice({
  name: "destination",
  initialState,
  reducers: {
    createDestination: createDestinationReducer,
    createDestinationSuccess: createDestinationSuccessReducer,
    createDestinationFailure: createDestinationFailureReducer,
    testDestination: testDestinationReducer,
    testDestinationSuccess: testDestinationSuccessReducer,
    testDestinationFailure: testDestinationFailureReducer,
    fetchDestinationServiceAccount: fetchDestinationServiceAccountReducer,
    fetchDestinationServiceAccountSuccess:
      fetchDestinationServiceAccountSuccessReducer,
    fetchDestinationServiceAccountFailure:
      fetchDestinationServiceAccountFailureReducer,
    sendTestDestinationLocalError: sendTestDestinationLocalErrorReducer,
    resetTest: resetTestReducer,
  },
});

export const {
  createDestination,
  createDestinationSuccess,
  createDestinationFailure,
  testDestination,
  testDestinationSuccess,
  testDestinationFailure,
  fetchDestinationServiceAccount,
  fetchDestinationServiceAccountSuccess,
  fetchDestinationServiceAccountFailure,
  sendTestDestinationLocalError,
  resetTest,
} = destinationSlice.actions;

// Selectors
export const selectDestinationTest = (state: RootState) =>
  state.destination.destinationTest;
export const selectDestinationCreate = (state: RootState) =>
  state.destination.destinationCreate;
export const selectDestinationServiceAccount = (state: RootState) =>
  state.destination.destinationServiceAccount;

export function* destinationSagas() {
  yield all([
    watchCreateDestination(),
    watchCreateDestinationSuccess(),
    watchTestDestination(),
    watchFetchDestinationServiceAccount(),
  ]);
}
export default destinationSlice.reducer;
