import React from "react";

import BrandedLogo from "../../components/BrandedLogo";

export type NotFoundProps = {
  header?: string;
  message?: string;
  orgId?: string;
};

const NotFound = ({ header, message, orgId }: NotFoundProps) => {
  return (
    <>
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <span className="sr-only">Not found.</span>
            <div className="flex-shrink-0 inline-flex items-center justify-center w-48 rounded-lg">
              <BrandedLogo orgId={orgId} />
            </div>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-primary-600 uppercase tracking-wide">
                404 error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                {header ?? "Not found."}
              </h1>
              <p className="mt-2 text-base text-gray-500">
                {message ?? "The requested page was not found."}
              </p>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default NotFound;
