import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { ToastInterface } from "@prequel-internal/react-components";

import { RootState } from "..";

type AlertsState = {
  alerts: Array<ToastInterface>;
};
const initialState: AlertsState = {
  alerts: [],
};

const removeAlertReducer: CaseReducer<
  AlertsState,
  PayloadAction<{
    toastId: string;
  }>
> = (state, action) => {
  state.alerts = state.alerts.filter(
    (alert) => alert.id !== action.payload.toastId
  );
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    removeAlert: removeAlertReducer,
  },
  extraReducers: (builder) => {
    builder.addDefaultCase((state, action) => {
      if (
        !action.payload?.error ||
        action.payload?.error?.suppressGlobalNotification
      ) {
        return state;
      }

      // if the action is a 404, manually suppress the notification
      if (action.payload?.error?.response?.status === 404) {
        return state;
      }

      let alert: ToastInterface | null = null;
      // the only error we get in payload is AxiosError (for now)
      if (action.payload?.error) {
        alert = {
          id: new Date().getTime().toString(),
          kind: "ERROR",
          message:
            action.payload.error.response?.data?.message ??
            action.payload.error.message ??
            "Unknown message",
        };
      } else {
        alert = action.error;
      }

      state.alerts = alert ? [...state.alerts, alert] : state.alerts;
    });
  },
});

export const { removeAlert } = alertsSlice.actions;

// Selectors
export const selectAlerts = (state: RootState) => state.alerts.alerts;

export default alertsSlice.reducer;
