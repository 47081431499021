import React from "react";
import { Toast } from "@prequel-internal/react-components";
import { useTypedDispatch, useTypedSelector } from "../../store"; // Redux - global state type import
import { removeAlert, selectAlerts } from "../../store/alerts/alerts.duck";

const Alerts = () => {
  const dispatch = useTypedDispatch();
  const dispatchRemoveAlert = (toastId: string) =>
    dispatch(removeAlert({ toastId }));

  const alerts = useTypedSelector((state) => selectAlerts(state));
  if (!alerts || alerts.length === 0) {
    return null;
  }

  return (
    <div className="fixed p-5 space-y-1 z-20 w-1/3 bottom-0 right-0 overflow-y-auto">
      {alerts.map((alert) => (
        <Toast
          key={alert.id}
          toast={alert}
          dismissHandler={dispatchRemoveAlert}
        />
      ))}
    </div>
  );
};

export default Alerts;
