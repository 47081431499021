import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";

import MagicLink from "./containers/MagicLink";
import Success from "./containers/Success";
import NotFound from "./containers/NotFound";
import Alerts from "./containers/Alerts";

const App = () =>
  useRoutes([
    {
      path: "/orgs/:orgId/export?/links/:linkId/success",
      element: <Success />,
    },
    {
      path: "/staging/orgs/:orgId/export?/links/:linkId/success",
      element: <Success />,
    },
    {
      path: "/orgs/:orgId/export?/links/:linkId",
      element: <MagicLink prodEnv={true} />,
    },
    {
      path: "/staging/orgs/:orgId/export?/links/:linkId",
      element: <MagicLink prodEnv={false} />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

const AppWrapper = () => (
  <>
    <Alerts />
    <Router>
      <App />
    </Router>
  </>
);

export default AppWrapper;
